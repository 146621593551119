import paidDeliveryImg from 'img/paidDelivery.png';
import { round } from 'utils/helpers';

export const loadState = () => {
  try {
    const serialized = localStorage.getItem('cart');

    if (serialized) {
      const parsed = JSON.parse(serialized);

      if (Array.isArray(parsed) && parsed.every(product => product.id)) {
        return {
          products: parsed,
          changed: [],
          sold: [],
          sum: calculateSum(parsed),
          isModalOpened: false,
        };
      }
    }

    localStorage.setItem('cart', JSON.stringify([]));

    return {
      products: [],
      changed: [],
      sold: [],
      oldSum: 0,
      sum: 0,
      isModalOpened: false,
    };
  } catch (err) {
    localStorage.removeItem('cart');

    return {
      products: [],
      changed: [],
      sold: [],
      oldSum: 0,
      sum: 0,
      isModalOpened: false,
    };
  }
};

export const saveState = products => {
  try {
    const serialized = JSON.stringify(products);
    localStorage.setItem('cart', serialized);
  } catch (err) {}
};

export const calculateSum = products => {
  return round(products.reduce((sum, { currentPrice, qty }) => (sum += currentPrice * qty), 0));
};

export const prepareState = (state, darkstore, shouldDeletePackage) => {
  const result = { ...state };
  if (result.products.length > 0) {
    if (darkstore.defaultCart && !shouldDeletePackage) {
      result.products = [...result.products, ...darkstore.defaultCart];
    }
    const sumWithoutDelivery = calculateSum(result.products);
    result.deliveryPrice = 0;
    if (darkstore.paidDelivery) {
      Object.entries(darkstore.paidDelivery.price).forEach(([border, price]) => {
        if (sumWithoutDelivery >= Number(border)) {
          result.deliveryPrice = price;
        }
      });
      result.products = [
        ...result.products,
        {
          id: darkstore.paidDelivery.id,
          name: darkstore.paidDelivery.name,
          img: paidDeliveryImg,
          qty: 1,
          currentPrice: result.deliveryPrice,
          readOnly: true,
          paidDelivery: true,
        },
      ];
    }
    result.sum = round(sumWithoutDelivery + result.deliveryPrice);
  }
  return result;
};
